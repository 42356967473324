<template>
  <div class="mian-info">
    <slot name="video-box"></slot>
    <div class="img">
      <slot name="img"></slot>
    </div>
    <div class="info">
      <h2>{{ title }}</h2>
      <div>
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'info-slot',
  props:['title'],

}
</script>

<style lang="scss" scoped>
.mian-info {
  // height: 420px;
  background: url('https://oss.culturalcloud.net/furong/202311/01101213rv0g.png') no-repeat center center scroll;
  background-size: 100% 100%;
  padding: 30px;
  display: flex;
  .img {
    width: 40%;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .info {
    flex: 1;
    h2 {
      color:#3A8DA6 ;
      font-size: 32px;
      line-height: 64px;
    }
    p {
      line-height: 28px;
      font-size: 14px;
    }
  }

}
</style>