<template>
  <div class="container" v-if="content">
    <breadcrumb :current="{ title: content.title }"></breadcrumb>
    <div class="video-box">
      <div class="audio-bg">
        <div class="img-container">
          <img :src="content.titleImg" alt="">
          <!-- <img :src="content.titleImg" class="authorImg"/></img> -->
        </div>
        <div class="info">
          <p class="title">{{ content.title }}</p>
          <p class="orgin">来源: {{ content.origin || '暂无' }}</p>
          <audio controls ref="audio" v-if="content.audioUrl" style="width: 700px;" class="aud" controlsList='nodownload'>
            <source :src="content.audioUrl" />
          </audio>
        </div>
      </div>
    </div>
     <!-- 选项卡 -->
     <category isDetail @change="changeCategory" :categorys="categorys"></category>
     <txt-view v-if="categoryIndex === 0" class="txt" :txt="content.txt"></txt-view>
     <comment v-else :id="$route.params.id" :contentType="$route.meta.contentType" />
     <recommend url="audio" :id="$route.params.id"  :contentType="$route.meta.contentType" />
  </div>
</template>

<script>
import {
    getAudioDetail
} from "@/api/audio/audio";
// import {getPicsDetail} from "@/api/pics/pics";

import MainInfo from "@/components/MainInfo";
// import AudioPlayer from '@/components/CommonAudio'
import Recommend from '@/components/RelatedRecommend'


export default {
  name:'video-detail',
  components: {
    MainInfo,Recommend
  },
  watch: {
     $route(){
      this.getData()

     } 
    },
  data() {
    return {



      current: 0,
      content:null,
      categoryIndex: 0,//分类下标
      categorys:[
            {
                name: '音频详情',
                newsType: 1
            },{
                name: '评论',
                newsType: 2
            }
      ]
    }
  },
  created () {
      this.getData()
  },
  methods: {
    swiper(e) {
      this.current = e
    },
    showstep(e){
      this.$refs.carousel.setActiveItem(e)
    },
    changeCategory(index) {
            this.categoryIndex = index
            // this.getList()
        },
    getData() {
      getAudioDetail(this.$route.params.id).then(res=>{
        if(res.code === 0){
          // res.data.picArr=JSON.parse(res.data.picsJson)
          this.content=res.data
          // this.playerOptions.sources[0].src = res.data.pullUrl;

          // console.log(res);
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
 

.container {
  width: 1200px;
    margin: 0 auto;
    .video-box {
      // height: 800px;
      background: url('https://oss.culturalcloud.net/furong/202311/02101728qrki.png') no-repeat center center scroll;
      background-size: 100% 100%;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      .audio-bg {
        
        background: url('https://oss.culturalcloud.net/furong/202311/10160452a5bp.png') no-repeat center center scroll;
      background-size:contain;
      // height: 350px;
      width: 100%;
      padding: 100px;
      display: flex;
      .img-container {
        // position: absolute;
        // top: 35%;
        // left: 50%;
        margin-right: 50px;
        width: 150px;
        height: 150px;
        background: url('https://oss.culturalcloud.net/furong/202311/10161510rq3c.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.3);
        transition: all 1s linear;
        position: relative;
        img {
          border-radius: 50%;
          width: 100px;
          height: 100px;
          position: absolute;
          left: 50%;
			    top: 50%;
          transform: translate(-50px,-50px);
          background-size:contain;
        }
        
      }
      .info {
        color: #fff;
          .title {
            font-size: 32px;
            margin-bottom: 20px;
            // font-weight: bold;
          }
          .orgin {
            font-size: 14px;
            margin-bottom:20px;
            // line-height: ;
            
          }
        }
      // padding: 30px;
      }
      
    }
}
</style>
