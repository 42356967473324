import request from '@/utils/request'


// 获得视频分页
export function getAudioPage(query) {
    return request({
        url: '/ich/audio/page',
        method: 'get',
        params: query
    })
}

// 获得直播分页
export function getAudioDetail(id) {
    return request({
        url: '/ich/audio/get?id='+id,
        method: 'get',
        
    })
}