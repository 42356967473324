<template>
  <div>
    <h1>相关推荐</h1>
    <div class="box">
      <div class="box-item" v-for="item in list" :key="item.id" @click="toDetail(item)">
        <div class="bgc">
          <img :src="item.titleImg" alt="">
          <div class="title">
          <p >{{ item.title }}</p>

          </div>  
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { getDetail } from "@/api/recommend/recommend";
export default {
  name: 'related-recommend',
  props: ['url', 'id','contentType'],
  data() {
    return {
      list: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    toDetail(item){
      // log
      if(this.contentType === 2){
        this.$router.replace('/shangfeiyi/project-detail/'+item.id)
      }else if(this.contentType === 3){
        this.$router.replace('/shangfeiyi/people-detail/'+item.id)
      }else if(this.contentType === 4){
        this.$router.replace('/wanfeiyi/detail/'+item.id)
      }else if(this.contentType === 5){
        this.$router.replace('/bofeiyi/detail/'+item.id)
      }else if(this.contentType === 6){
        this.$router.replace('/xuefeiyi/videoDetail/'+item.id)
      }else if(this.contentType === 8){
        this.$router.replace('/xuefeiyi/picsDetail/'+item.id)
      }else if(this.contentType === 7){
        this.$router.replace('/xuefeiyi/audioDetail/'+item.id)
      }
      this.$nextTick(()=>{
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      })
     
      console.log(this.contentType);
    },
    getData() {
      getDetail(this.url, { id: this.id }).then(res => {
        if (res.code === 0) {
          this.list = res.data
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
h1 {
  font-size:24px ;
  line-height: 48px;
}
.box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  // justify-content: space-between;
  .box-item {
    width: 25%;
    padding-right: 30px;
    
   

    .bgc {
      background-color: #f5f5f5;
      border-radius: 10px;
      overflow: hidden;

      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      .title {
        padding: 10px;
        font-size: 15px;
        p {
          word-wrap: break-word; /*强制换行*/
          overflow: hidden; /*超出隐藏*/
          text-overflow: ellipsis;/*隐藏后添加省略号*/
          white-space: nowrap;/*强制不换行*/

        }
        // font-weight: bold;
      }
    }



  }

}</style>